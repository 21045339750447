<template>
  <div class="table-content">
    <a-button
      :style="{ marginBottom: '20px' }"
      type="primary"
      size="large"
      @click="sync"
    >
      同步通讯录
    </a-button>
    <a-table
      :columns="columns"
      :data-source="list"
      rowKey="id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="employeeEdit(record)" style="margin-right: 20px">编辑</a>
        <a @click="eCardCode(record)">电子名片码</a>
      </span>
      <template #name="name, record">
        <template v-if="record.name">{{ record.name }}</template>
        <ww-open-data
          v-else
          type="userName"
          :openid="record.userid"
        ></ww-open-data>
      </template>
      <template #avatar="avatar">
        <img
          style="width: 38px; height: 38px; border-radius: 50%"
          :src="avatar"
          alt=""
        />
      </template>
    </a-table>
    <a-modal
      dialog-class="modal-content"
      width="600px"
      title="编辑"
      okText="确定"
      cancelText="取消"
      :visible="employeeVisible"
      @ok="employeeConfirm"
      @cancel="
        employeeVisible = false
        employeeForm = {}
        $refs.form.clearValidate()
      "
    >
      <a-form-model
        ref="form"
        :model="employeeForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-form-model-item
          label="职位"
          prop="position_id"
          :rules="{ required: true, message: '请选择职位' }"
        >
          <a-select v-model="employeeForm.position_id" placeholder="请选择职位">
            <a-select-option
              v-for="item in positionList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getEcardCode,
  getEmployeeList,
  setEmployeePosition,
  sync,
} from '@/api/employee'
import _ from 'lodash'
import { getPositionList } from '@/api/position'

export default {
  name: 'EmployeeList',
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '职位',
          dataIndex: 'position.name',
          customRender: (name) => name || '暂无职位',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      positionList: [],
      employeeForm: {
        position_id: undefined,
      },
      employeeVisible: false,
      list: [],
      pagination: {
        current: 1,
        defaultPageSize: 15,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  mounted() {
    this.getList()
    this.getPositionList()
  },
  methods: {
    getPositionList() {
      getPositionList().then((res) => {
        if (res.code === 0) {
          this.positionList = res.data
        }
      })
    },
    // 同步通讯录
    sync() {
      this.$confirm({
        title: '提示',
        content: '确定要同步吗',
        icon: () =>
          this.$createElement('a-icon', {
            props: { type: 'info-circle' },
            style: 'color:#e63e30',
          }),
        onOk: () => {
          this.$message.loading({
            content: '同步中',
            key: 'sync',
            duration: 0,
          })
          sync().then((res) => {
            if (res.code === 0) {
              this.$message.success({
                content: res.msg,
                key: 'sync',
              })

              this.data = []
              this.getList()
            } else {
              this.$message.error({ content: '同步错误', key: 'sync' })
            }
          })
        },
      })
    },
    getList(page = 1) {
      this.$store.dispatch('loading/startContentLoading')
      getEmployeeList({ page }).then(async (res) => {
        if (res.code === 0) {
          const pagination = { ...this.pagination }
          pagination.total = res.data.total
          this.list = res.data.data
          this.pagination = pagination
        }
        this.$store.dispatch('loading/endContentLoading')
      })
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getList(pagination.current)
    },
    employeeEdit(record) {
      this.employeeForm = _.cloneDeep(record)
      if (!this.employeeForm.position) this.employeeForm.position_id = undefined
      this.employeeVisible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    eCardCode(record) {
      this.$message.loading({ content: '下载中', key: 'download' })
      getEcardCode({ id: record.id }).then((res) => {
        if (res.code === 0) {
          this.downloadIamge(res.data.qr_code, record.name).then(() =>
            this.$message.success({ content: '下载成功！', key: 'download' })
          )
        }
      })
    },
    downloadIamge(imgsrc, name) {
      return new Promise((resolve) => {
        // 下载图片地址和图片名
        const image = new Image()
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
          const a = document.createElement('a') // 生成一个a元素
          const event = new MouseEvent('click') // 创建一个单击事件
          a.download = name || 'photo' // 设置图片名称
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
          resolve()
        }
        image.src = imgsrc
      })
    },
    employeeConfirm() {
      this.$refs.form
        .validate()
        .then(() => {
          this.$message.loading({ content: '保存中', key: 'setPosition' })
          setEmployeePosition({
            id: this.employeeForm.id,
            position_id: this.employeeForm.position_id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success({
                content: '保存成功',
                key: 'setPosition',
              })
              this.employeeVisible = false
              this.getList()
            }
          })
        })
        .catch((err) => err)
    },
  },
}
</script>
